@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@400;700&display=swap');

/* BASE COLOR
------------------------------------------------------------*/
$normal: #262525;
$link: #D02;
$base: #d60303;
$gray: #f5f5f6;
$border:#e9e8e8;
$ease: cubic-bezier(0.65, 0, 0.35, 1);

/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head:80px;
$sp-head:60px;

$pc-side:  40px;
$tab-side: 30px;
$sp-side:  15px;


@mixin fontawesome($c){
	content: "#{$c}";
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
}


@mixin grd(){
	background-color: $base;
	background-image: linear-gradient(-319deg, $base 0%, #f75709 100%);
}

@mixin flex($parent:wrap , $child:space-between){
	display:-webkit-flex;
	display:-moz-flex;
	display:-o-flex;
	display:-ms-flex;
	display:flex;
	-moz-flex-flow:row $parent;
	-webkit-flex-flow:row $parent;
	-o-flex-flow:row $parent;
	-ms-flex-flow:row $parent;
	flex-flow:row $parent;
	-webkit-justify-content:$child;
	-o-justify-content:$child;
	-ms-justify-content:$child;
	-moz-justify-content:$child;
	justify-content:$child;
}

@mixin flexfont($min: 1rem, $size: 30){
	font-size: calc(#{$min} + #{$size} * (100vw - 450px)/ 900);
}

@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 100;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 200;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 300;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 400;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 500;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Bold");
	font-weight: bold;
}

@mixin font(){
	font-family: 'Roboto', 'Noto Sans JP', "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
	font-weight: 400;
	-webkit-font-feature-settings : "palt";
	font-feature-settings : "palt";
	letter-spacing:0.024em;
}
@mixin min(){
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	font-weight: 500;
	-webkit-font-feature-settings : "palt";
	font-feature-settings : "palt";
	letter-spacing:0.04em;
}

@mixin default($width:auto,$font:14px,$color:#000,$link:#D00,$bg:#FFF){
	*{
		margin:0;
		padding:0;
	}
	*, *:before, *:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
	}
	html{
		-webkit-text-size-adjust: none;
	}
	body{
		color: $color;
		background:$bg;
		font-size:$font;
		line-height:1.8;
		@include font();
	}
	h1,h2,h3,h4,h5,h6{
		font-weight:500;
	}
	img {
		-ms-interpolation-mode: bicubic;
		vertical-align:bottom;
		image-rendering: -webkit-optimize-contrast;
	}
	i,strong{
		font-style:normal;
	}
	a{
		text-decoration:none;
		outline:none;
		cursor:pointer;
		color: $link;
		&:hover{
            text-decoration:none;
			color: lighten($link,10%);
			img{
				opacity:.8;
		  	}
		}
	}
	.clear{
		clear:both;
	}
	.clearfix:after{
		content:"";
		display:block;
		clear:both;
	}
	.display{
		width:$width;
		margin:0 auto;
		position:relative;
		top:0;
		left:0;
	}
    ul,ol{
        list-style: none;
    }
	
	@media screen and (max-width: $tab){
		input[type="text"],
		input[type="tel"],
		input[type="email"],
		input[type="password"],
		input[type="submit"],
		input[type="button"],
		select,
		textarea{
			-webkit-appearance: none;
		}
	}
}