.secondary{
	background-color: $normal;
	background-position: center center;
	background-size: cover;
	height: 450px;
	@include flex();
	align-items: center;
	position: relative;
	color: #FFF;
	@media screen and (max-width: $tab){
		height: 360px;	
	}
	@media screen and (max-width: $phone){
		height: 240px;	
	}
	&:before,
	&:after{
		content: '';
		width: 100%;
		height: 100%;
		background: darken($normal, 10%);
		position: absolute;
		top: 0;
		left: 0;
		opacity: .12;
		z-index: 1;
		@media screen and (max-width: $phone){
			opacity: .48;	
		}
	}
	&:after{
		height: 33%;
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		opacity: .32;
		@media screen and (max-width: $phone){
			opacity: .56;	
		}
	}
	a{
		color: #FFF;
	}
	&__main{
		margin: 32px auto 0;
		text-align: center;
		position: relative;
		z-index: 2;
		&__title{
			line-height: 1;
			font-size: 1.24rem;
			@media screen and (max-width: $phone){
				font-size: 1.12rem;	
			}
			&:before{
				content: attr(data-eng);
				font-size: 4rem;
				font-weight: 700;
				text-transform: uppercase;
				display: block;
				margin: 0 0 16px;
				@media screen and (max-width: $phone){
					font-size: 2.44rem;
					margin: 0 0 8px;
				}
			}
		}
	}
	&__breadcrumb{
		position: absolute;
		left: $pc-side;
		bottom: 32px;
		font-size: .8rem;
		z-index: 2;
		@media screen and (max-width: $tab){
			left: $tab-side;
			bottom: 24px;
		}
		@media screen and (max-width: $phone){
			left: $sp-side;
			bottom: 16px;
			width: calc(100% - #{$sp-side});
			overflow: auto;
		}
		ol{
			@include flex();
			align-items: center;
			justify-content: flex-start;
			@media screen and (max-width: $phone){
				flex-wrap: nowrap;
			}
			li{
				@media screen and (max-width: $phone){
					white-space: nowrap;
				}
				&:after{
					@include fontawesome('\f105');
					font-size: .72rem;
					display: inline-block;
					margin: 0 12px;
				}
				&:first-child{
					&:before{
						@include fontawesome('\f015');
						font-size: .72rem;
						display: inline-block;
						margin: 0 6px 0 0;
					}
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
	}
}