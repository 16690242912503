.site-contact{
	@mixin btn(){
		@include font();
		font-size: 1.12rem;
		display: inline-block;
		padding: 8px 64px;
		background: $base;
		color: #FFF;
		border-radius: 999px;
		border: $base 2px solid;
		&:hover{
			color: $base;
			background-color: transparent;
		}
	}
	.contactbox{
		background: darken($gray, 1%);
		padding: 24px;
		text-align: center;
		border-radius: 4px;
		@include flex();
		@media screen and (max-width: $phone){
			padding: 15px;
			display: block;
		}
		&__data{
			width: calc(50% - 8px);
			background: #FFF;
			padding: 24px;
			border-radius: 6px;
			box-shadow: 0 0 24px rgba($normal, .04);
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 0 15px;
				&:last-of-type{
					margin: 0;
				}
			}
			dt{
				font-size: 1.32rem;
				font-weight: 700;
				margin: 0 0 2px;
			}
			&__btn{
				margin: 12px 0 0;
				a{
					@include btn();
					font-size: 1.08rem;
					min-width: 320px;
					@media screen and (max-width: $phone){
						min-width: 1px;
						display: block;
						padding: 12px 0;
					}
				}
			}
		}
	}
	.message{
		background: darken($gray, 1%);
		padding: 24px;
		margin: 0 0 48px;
		border-radius: 4px;
		@media screen and (max-width: $phone){
			padding: 15px;
			margin: 0 0 24px;
		}
		li{
			background: #FFF;
			font-size: .96rem;
			color: lighten($normal, 16%);
			padding: 16px 24px;
			padding-left: 64px;
			margin: 0 0 8px;
			border-radius: 3px;
			position: relative;
			&:before{
				@include fontawesome('\f00c');
				font-size: 1.32rem;
				color: $base;
				position: absolute;
				top: 50%;
				left: 24px;
				transform: translateY(-50%);
			}
			&:last-child{
				margin: 0;
			}
		}
	}
	
	.formstyle{
		width: 100%;
		border-collapse: collapse;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: lighten($border, 2%) 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					border-bottom: none;
				}
				th,
				td{
					text-align: left;
					vertical-align: middle;
					padding: 36px 0;
					@media screen and (max-width: $phone){
						display: block;
						padding: 15px 0;
					}
				}
				th{
					font-size: 1.08rem;
					width: 300px;
					@media screen and (max-width: $phone){
						width: auto;
						background: $gray;
						padding: 15px;
					}
					&.hiss{
						&:after{
							content: '必須項目';
							color: #FFF;
							font-size: .8rem;
							font-weight: 500;
							background: $base;
							padding: 3px 15px;
							border-radius: 3px;
							float: right;
						}
					}
				}
				td{
					padding-left: 48px;
					@media screen and (max-width: $phone){
						padding-left: 0;	
					}
					input[type='text'],
					input[type='tel'],
					input[type='email'],
					input[type='password'],
					select,
					textarea{
						@include font();
						font-size: 1rem;
						width: 100%;
						background: darken($gray, 1%);
						padding: 10px 12px;
						border: none;
						border-radius: 3px;
						display: block;
						outline: none;
						transition: all 200ms $ease;
						transition-property: background, box-shadow, transform;
						&:focus{
							background: #FFF;
							box-shadow: 0 0 32px rgba($normal, .12);
							transform: scale(1.02);
						}
					}
					textarea{
						height: 180px;
					}
				}
			}
		}
        .mt{
            margin-top: 10px;
        }
        .flex{
            @include flex();
            margin: 0 0 0 -32px;
            justify-content: flex-start;
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
            &__item{
                width: 50%;
                padding: 0 0 0 32px;
                @include flex();
                align-items: center;
                margin: 32px 0 0;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 12px;
					padding: 0;
					&:last-child{
						margin: 0;
					}
				}
                &:nth-of-type(-n+2){
                    margin-top: 0;
                }
                &__title{
                    width: 80px;
                }
                &__content{
                    width: calc(100% - 80px);
                    padding: 0 0 0 12px;
                }
            }
        }
		
		/* ContactForm7 Format */
		.wpcf7-list-item{
			display: inline-block;
			margin: 0 12px 0 0;
			@media screen and (max-width: $phone){
				margin: 4px 8px 4px 0;	
			}
			label{
				background: darken($gray, 1%);
				padding: 6px 16px;
				border-radius: 3px;
				@include flex();
				align-items: center;
				cursor: pointer;
				@media screen and (max-width: $phone){
					padding: 5px 12px;
					font-size: .92rem;
				}
				input{
					&:checked{
						+ *{
							color: $base;
						}
					}
				}
				.wpcf7-list-item-label{
					margin: 0 0 0 8px;
				}
			}
		}
	}
    .send{
        margin: 40px 0 0;
		text-align: center;
        input{
            @include btn();
			font-size: 1.32rem;
			padding: 16px 80px;
			cursor: pointer;
			border: $base 2px solid;
			@media screen and (max-width: $phone){
				font-size: 1.56rem;
				padding: 12px 0;
				width: 100%;
			}
			&:hover{
				color: $base;
				background: #FFF;
			}
        }
    }
}