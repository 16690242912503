.site-blog{
	.clums{
		@include flex();
		align-items: flex-start;
		@media screen and (max-width: $phone){
		}
		&__container{
			width: calc(100% - 340px);
			padding: 0 0 0 40px;
			order: 2;
			@media screen and (max-width: $phone){
				width: 100%;
				order: 1;
				padding: 0;
				margin: 0 0 40px;
			}
			&__title{
				font-size: 1.72rem;
				line-height: 1.56;
				font-weight: 700;
				margin: 0 0 16px;
				padding: 0 0 16px;
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					font-size: 1.32rem;	
				}
				small{
					font-size: 1.04rem;
					display: inline-block;
					margin: 0 0 0 16px;
				}
			}
			.catenav{
				border: $border 1px solid;
				border-radius: 3px;
				padding: 16px 20px;
				margin: 0 0 40px;
				@media screen and (max-width: $phone){
					padding: 15px;
					margin: 0 0 24px;
				}
				&__title{
					font-size: 1.24rem;
					font-weight: 700;
					margin: 0 0 8px;
					i{
						display: inline-block;
						margin: 0 6px 0 0;
					}
				}
				&__list{
					@include flex();
					justify-content: flex-start;
					li{
						margin: 4px 16px;
						margin-left: 0;
						@media screen and (max-width: $phone){
							margin-right: 8px;
							font-size: .88rem;
						}
						a{
							background: darken($gray, 1%);
							color: $normal;
							display: block;
							padding: 2px 16px;
							border-radius: 3px;
							&:hover{
								background: $normal;
								color: #FFF;
							}
							&.active{
								background: $base;
								color: #FFF;
							}
						}
					}
				}
			}
			.bloglist{
				@include flex();
				align-items: flex-start;
				justify-content: flex-start;
				margin: 0 0 0 -24px;
				@media screen and (max-width: $phone){
					display: block;
					margin: 0;
				}
				&__item{
					width: 50%;
					padding: 0 0 0 24px;
					margin: 24px 0 0;
					@media screen and (max-width: $phone){
						width: auto;
						padding: 0;
						margin: 0 0 16px;
					}
					&:nth-child(-n+2){
						margin-top: 0;
					}
					&__box{
						background: #FFF;
						color: $normal;
						padding: 16px;
						border: $border 1px solid;
						display: block;
						@media screen and (max-width: $phone){
							@include flex();	
						}
                        &:hover{
                            .bloglist__item__box__pic{
                                img{
                                    opacity: 1;
                                }
                                &:before{
                                    opacity: .64;
                                }
                                &:after{
                                    opacity: 1;
                                    top: 50%;
                                }
                            }
                        }
						&__pic{
							width: 100%;
							padding-top: 63%;
							position: relative;
							overflow: hidden;
							@media screen and (max-width: $phone){
								width: 100px;
								height: 100px;
								padding: 0;
							}
							img{
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
							&:before{
								content: '';
								background: $normal;
								width: 100%;
								height: 100%;
								position: absolute;
								opacity: 0;
								top: 0;
								left: 0;
								z-index: 2;
								transition: opacity 400ms $ease;
							}
							&:after{
								content: 'MORE';
								font-size: .92rem;
								background: #FFF;
								border-radius: 999px;
								padding: 4px 40px;
								position: absolute;
								top: 60%;
								left: 50%;
								opacity: 0;
								transform: translate(-50%, -50%);
								z-index: 2;
								transition: opacity 400ms $ease, top 400ms $ease;
							}
						}
						&__data{
							margin: 16px 0 0;
							@include flex();
							align-items: flex-start;
							@media screen and (max-width: $phone){
								width: calc(100% - 100px);
								margin: 0;
								padding: 0 0 0 16px;
							}
							time{
								font-size: .88rem;
								background: darken($gray, 1%);
								padding: 2px 16px;
								display: inline-block;
							}
							.category{
								@include flex();
								justify-content: flex-start;
								li{
									font-size: .72rem;
									background: $base;
									color: #FFF;
									padding: 2px 8px;
									margin: 4px 8px 0 0;
									&:last-child{
										margin-right: 0;
									}
								}
							}
							h3{
								width: 100%;
								font-size: 1.16rem;
								font-weight: 700;
								line-height: 1.56;
								margin: 12px 0 0;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								@media screen and (max-width: $phone){
									margin: 8px 0 0;	
								}
							}
						}
					}
				}
			}
		}
		
		// サイドバー
		&__sidebar{
			width: 340px;
			position: sticky;
			top: 96px;
			left: 0;
			order: 1;
			@media screen and (max-width: $phone){
				width: 100%;
				position: relative;
				top: 0;
				order: 2;
			}
			&__box{
				margin: 0 0 40px;
				@media screen and (max-width: $phone){
					margin: 0 0 24px;	
				}
				&:last-of-type{
					margin: 0;
				}
				h4{
					font-size: 1.32rem;
					font-weight: 700;
					background: darken($gray, 1%);
					border-radius: 3px;
					padding: 9px 16px;
					margin: 0 0 16px;
				}
				.blocks{
					border: $border 1px solid;
					border-radius: 3px;
					padding: 16px;
				}
				.photolist{
					&__item{
						margin: 0 0 16px;
						padding: 0 0 16px;
						border-bottom: $border 1px solid;
						&:last-child{
							margin: 0;
							padding: 0;
							border: none;
						}
						&__box{
							color: $normal;
							@include flex();
							align-items: flex-start;
							&__pic{
								width: 80px;
								height: 80px;
								img{
									width: 100%;
									height: 100%;
									object-fit: cover;
									object-position: center;
								}
							}
							&__data{
								width: calc(100% - 80px);
								padding: 0 0 0 20px;
								time{
									font-size: .88rem;
									background: darken($gray, 1%);
									display: inline-block;
									padding: 2px 12px;
								}
								h5{
									font-size: .96rem;
									font-weight: 700;
									line-height: 1.6;
									margin: 8px 0 0;
								}
							}
						}
					}
				}
				.catelist{
					&__item{
						margin: 0 0 16px;
						padding: 0 0 16px;
						border-bottom: $border 1px solid;
						&:last-child{
							margin: 0;
							padding: 0;
							border: none;
						}
						&__box{
							color: $normal;
							display: block;
							padding: 0 24px 0 0;
							position: relative;
							&:after{
								@include fontawesome('\f061');
								font-size: .72rem;
								color: $base;
								position: absolute;
								top: 50%;
								right: 0;
								transform: translateY(-50%);
							}
						}
					}
				}
			}
		}
	}
}