.site-index{
    .firstview{
        position: relative;
        width: 100%;
        height: 100vh;
        background: $normal;
        @include flex();
        align-items: center;
        justify-content: flex-start;
		@media screen and (max-width: $tab){
			height: calc(100vw / 16 * 9);	
		}
        &__bg{
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
			video{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
        }
        &__topics{
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 20;
			@media screen and (max-width: $tab){
				display: none;
			}
            &__box{
                background: $gray;
                color: $normal;
                @include flex();
                align-items: center;
                padding: 28px 32px;
                padding-right: 64px;
                min-width: 560px;
                width: 33.3vw;
                position: relative;
                &:hover{
                    dl{
                        dd{
                            text-decoration: underline;
                        }
                    }
                    &:after{
                        right: 24px;
                        color: $base;
                        opacity: 1;
                    }
                }
                &:after{
                    @include fontawesome('\f061');
                    font-size: .88rem;
                    color: $normal;
                    opacity: .24;
                    position: absolute;
                    top: 50%;
                    right: 32px;
                    transform: translateY(-50%);
                    transition: all 120ms $ease;
                }
                time{
                    width: 88px;
                    font-size: .82rem;
                }
                dl{
                    width: calc(100% - 88px);
                    @include flex();
                    align-items: center;
                    line-height: 1.6;
                    dt{
                        width: 88px;
                        text-align: center;
                        font-size: .82rem;
                        color: #FFF;
                        background: $base;
                        padding: 2px 8px;
                    }
                    dd{
                        width: calc(100% - 88px);
                        padding: 0 0 0 24px;
                        font-size: .92rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        &__sns{
            position: absolute;
            top: 50%;
            right: $pc-side;
            transform: translateY(-50%);
            z-index: 20;
			@media screen and (max-width: $tab){
				right: $tab-side;	
			}
			@media screen and (max-width: $phone){
				display: none;
			}
            ul{
                li{
                    margin: 0 0 16px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        $size: calc(32px + .2vmin);
                        width: $size;
                        height: $size;
                        border-radius: 50%;
                        border: #FFF 1px solid;
                        color: #FFF;
                        font-size: .88rem;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        transition: all 120ms $ease;
						transition-property: transform opacity color;
                        position: relative;
                        &:before{
                            content: '';
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            border-radius: 50%;
                            background: #FFF;
                            z-index: 1;
							opacity: 0;
                            transition: transform 120ms $ease;
                        }
                        i{
                            display: inline-block;
                            position: relative;
                            z-index: 2;
                            transition: transform 120ms $ease;
                        }
                        &:hover{
							color: $base;
                            transform: scale(.9);
                            &:before{
                                transform: scale(1.3);
								opacity: 1;
                            }
                            i{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
		&__scroll{
			position: absolute;
			right: $pc-side;
			bottom: 0;
			font-size: .8rem;
			font-weight: 700;
			color: #FFF;
			text-transform: uppercase;
			white-space: nowrap;
			padding: 0 28px 120px 0;
			z-index: 20;
			@media screen and (max-width: $tab){
				display: none;
			}
			&__txt{
				position: absolute;
				top: -3px;
				right: 0;
				letter-spacing: .32em;
				text-indent: .32em;
				display: block;
				text-align: center;
				-webkit-writing-mode: vertical-rl;
				-moz-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
			}
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				background: #FFF;
				width: 2px;
				height: 100%;
				animation: sc 1600ms $ease infinite;
				@keyframes sc{
					0%{
						height: 100%;
						bottom: 0;
					}
					45%{
						height: 0;
						bottom: 0;
					}
					55%{
						height: 0;
						bottom: 100%;
					}
					100%{
						height: 100%;
						bottom: 0;
					}
				}
			}
		}
    }
}