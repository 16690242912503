#site-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 32px $pc-side;
	@include flex();
	z-index: 1000;
	color: #FFF;
	transition: all 200ms ease-in-out;
	transition-property: background color padding box-shadow;
	@media screen and (max-width: $tab){
		padding-left: $tab-side;
		padding-right: $tab-side;
	}
	@media screen and (max-width: $phone){
		padding: 0;
		z-index: 5000;
	}
	&.active{
        background: #FFF;
        color: $normal;
        padding-top: 16px;
        padding-bottom: 16px;
        box-shadow: 0 0 32px rgba($normal, .088);
		@media screen and (max-width: $phone){
			padding-top: 0;
			padding-bottom: 15px;
		}
        .logos{
            transform: translateY(-2px);
			@media screen and (max-width: $phone){
				margin-top: 15px;
			}
            &:after{
                opacity: 0;
            }
            img{
                opacity: 1;
            }
        }
		.gnavi{
			ul{
				li{
					a{
						&:after{
							bottom: -30px;
						}
					}
				}
			}
		}
        a{
            color: $normal;
        }
        .contact{
			color: #FFF;
			&:hover{
				border-color: $base;
			}
        }
		.menubtn{
			@media screen and (max-width: $phone){
				margin-top: 0;
			}
		}
	}
	a{
		color: #FFF;
	}
	.logos{
		position: relative;
		transform: translateY(-12px);
		transition: transform 200ms $ease;
		z-index: 2;
		@media screen and (max-width: $phone){
			transform: translateY(-6px);
			margin: 20px 0 0 $sp-side;
		}
		&:after,
		img{
			transition: opacity 200ms linear;
		}
		&.active{
			@media screen and (max-width: $phone){
				&:after{
					opacity: 0;
				}
				img{
					opacity: 1;
				}
			}
		}
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			background: url(/images/common/logo_white_2x.png) no-repeat 0 0 / contain;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 1;
			z-index: 2;
			pointer-events: none;
		}
		img{
			height: 42px;
			position: relative;
			display: block;
			opacity: 0;
			z-index: 1;
			@media screen and (max-width: $phone){
				height: 36px;	
			}
		}
	}
	.gnavi{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@media screen and (min-width: $phone + 1px){
			display: block !important;
		}
		@media screen and (max-width: $phone){
			//display: none;
			width: 100%;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			top: 0;
			left: 0;
			transform: translate(0);
            @include flex();
            align-items: center;
            justify-content: center;
			background: #FFF;
			a{
				color: $normal;
			}
		}
		ul{
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
			}
			li{
				font-size: 1.08rem;
				margin: 0 3.33vw 0 0;
				a{
					position: relative;
					&:after{
						content: '';
						width: 0;
						height: 3px;
						background: $base;
						border-radius: 999px;
						position: absolute;
						left: 50%;
						bottom: -12px;
						transform: translate(-50%, 0);
						transition: width 120ms $ease;
						@media screen and (max-width: $phone){
							display: none;
						}
					}
					&:hover{
						&:after{
							width: 48px;	
						}
					}
				}
				@media screen and (max-width: $phone){
					font-size: 1.64rem;
					text-align: center;
					width: 100%;
					margin: 0 0 8vh;
					&:last-child{
						margin-bottom: 0;
					}
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
		.spview{
			display: none;
			@media screen and (max-width: $phone){
				display: block;
				position: absolute;
				left: 50%;
				bottom: 32px;
				transform: translateX(-50%);
				z-index: 3;
			}
			&__contact{
				font-size: 1.32rem;
				display: block;
				@include grd();
				color: #FFF;
				padding: 8px 32px;
				white-space: nowrap;
				border-radius: 999px;
				i{
					display: inline-block;
					margin: 0 6px 0 0;
				}
			}
		}
	}

	.contact{
		@include grd();
		border: rgba($base, 0) 2px solid;
		color: #FFF;
		padding: 8px 32px;
		border-radius: 999px;
		display: block;
		&:hover{
			background: #FFF;
			color: $base;
		}
		@media screen and (max-width: $phone){
			display: none;
		}
		i{
			display: inline-block;
			margin: 0 6px 0 0;
		}
	}
	
	.menubtn{
		display: none;
		@media screen and (max-width: $phone){
			$size: 39px;
			width: $size;
			height: $size;
			background: $base;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 50%;
			right: $sp-side;
			transform: translate(8px ,-50%);
			cursor: pointer;
			margin: 10px $sp-side/2 0 0;
			&:before,
			&:after{
				content: '';
				width: 16px;
				height: 1px;
				background: #FFF;
				position: absolute;
				top: $size/2 - 3;
				left: 50%;
				transform: translate(-50%, 0);
				transition: all 200ms $ease;
			}
			&:after{
				top: auto;
				bottom: $size/2 - 3;
			}
			&.active{
				&:before,
				&:after{
					top: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
				}
				&:after{
					top: auto;
					bottom: 50%;
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
		}
	}
}