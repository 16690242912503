@charset "utf-8";

@import "module/reset";
@import "module/module";
@import "plugin/swiper";


:root{
	font-size: calc(14px + .08vmin);
	@media screen and (max-width: $tab){
		font-size: calc(13.5px + .08vmin);
	}
	@media screen and (max-width: $phone){
		font-size: calc(13px + .08vmin);
	}
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "module/wire";
@import "common/header";
@import "common/breadcrumb";
@import "common/footer";
@import "page/firstview";
@import "page/index";
@import "page/service";
@import "page/env";
@import "page/company";
@import "page/blog";
@import "page/edit";
@import "page/contact";

.site-module{
	&.wrap{
		padding: 108px 0;
		@media screen and (max-width: $tab){
			padding: 88px $tab-side;	
		}
		@media screen and (max-width: $phone){
			padding: 44px $sp-side;	
		}
		&.mid{
			padding: 96px 0;
			@media screen and (max-width: $tab){
				padding: 56px $tab-side;	
			}
			@media screen and (max-width: $phone){
				padding: 44px $sp-side;	
			}
		}
        &.no-top{
            padding-top: 0;
        }
        &.no-bottom{
            padding-bottom: 0;
        }
		&.scroll-border{
			position: relative;
			&:before{
				content: '';
				width: 1px;
				height: 96px;
				position: absolute;
				top: -48px;
				left: 50%;
				background: $base;
				animation: move 800ms $ease infinite;
				@media screen and (max-width: $tab){
					display: none;
				}
				@keyframes move{
					0%{
						height: 0;
						top: -48px;
					}
					50%{
						height: 96px;
						top: -48px;
					}
					100%{
						height: 0;
						top: 48px;
					}
				}
			}
		}
	}
	&.gray{
		background: $gray;
	}
	&.texture{
		background: url(/images/common/bg_tex_2x.png) repeat center / 36px;
	}
	&.texture-b{
		background: url(/images/common/bg_tex_b_2x.png) repeat center / 36px;
	}
	&__title{
		text-align: center;
		font-size: 1.16rem;
		font-weight: 700;
		margin: 0 0 32px;
		line-height: 1;
		display: block;
		@media screen and (max-width: $phone){
			font-size: 1.04rem;
			margin: 0 0 24px;
		}
		&:before{
			content: attr(data-eng);
			font-size: 3.72rem;
			letter-spacing: 0em;
			display: block;
			margin: 0 0 16px;
			@media screen and (max-width: $phone){
				font-size: 2.24rem;
				margin: 0 0 12px;
			}
		}
		&--horizontal{
			font-size: 1.24rem;
			font-weight: 700;
			margin: 0 0 32px;
			line-height: 1;
			@include flex();
			align-items: flex-end;
			justify-content: flex-start;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
			&:before{
				font-size: 2.4rem;
				content: attr(data-eng);
				display: inline-block;
				margin: 0 24px 0 0;
				transform: translateY(2px);
				@media screen and (max-width: $phone){
					font-size: 1.72rem;
					margin: 0 8px 0 0;
				}
			}
		}
	}
	&__table{
		width: 100%;
		background: #FFF;
		border: $border 1px solid;
		border-collapse: collapse;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					&:last-child{
						border-bottom: none;
					}
				}
				th,td{
					font-size: 1.02rem;
					padding: 20px 24px;
					text-align: left;
					vertical-align: middle;
					@media screen and (max-width: $phone){
						display: block;
						padding: 15px;
					}
				}
				th{
					width: 22.5%;
					background: $gray;
					@media screen and (max-width: $phone){
						width: auto;	
					}
				}
				td{
					padding-left: 40px;
					@media screen and (max-width: $phone){
						padding: 15px;	
					}
					.smlicon{
						font-size: .8rem;
						margin: 0 0 0 6px;
						display: inline-block;
						transform: translateY(-2px);
					}
				}
			}
		}
	}
	&__pager{
		margin: 32px 0 0;
		@include flex();
		align-items: center;
		justify-content: center;
		a,span{
			$size: 36px;
			font-size: .92rem;
			width: $size;
			height: $size;
			border-width: 2px;
			border-style: solid;
			border-radius: 50%;
			margin: 0 6px;
			@include flex();
			align-items: center;
			justify-content: center;
		}
		a{
			background: darken($gray, 4%);
			border-color: darken($gray, 4%);
			color: $normal;
			transition: transform 200ms $ease;
			&:hover{
				background: #FFF;
				transform: scale(1.08);
			}
		}
		span{
			background: $base;
			border-color: $base;
			color: #FFF;
		}
	}
	.btnarea{
		margin: 32px 0 0;
		&.center{
			text-align: center;
		}
		a{
			display: inline-block;
			@include grd();
			border: rgba($base, 0) 2px solid;
			color: #FFF;
			padding: 11px 48px;
			font-size: 1.24rem;
			@media screen and (max-width: $phone){
				display: block;
				text-align: center;
				padding: 11px 32px;
			}
			&.radius{
				border-radius: 999px;
			}
			&.arrow{
				padding-right: 64px;
				position: relative;
				@media screen and (max-width: $phone){
					padding-right: 32px;	
				}
				&:before{
					@include fontawesome('\f061');
					font-size: .88rem;
					position: absolute;
					top: 50%;
					right: 24px;
					transform: translateY(-50%);
				}
			}
			&:hover{
				background: transparent;
				border-color: $base;
				color: $base;
			}
		}
	}
}

.site-404{
	text-align: center;
	dt{
		font-size: 2rem;
		font-weight: 700;
	}
}

.scrollAnime{
	&__target{
		opacity: 0;
		transform: translateY(40px);
	}
	&._active{
		> .scrollAnime__target{
			opacity: 1;
			transform: translateY(0);
			transition: all 500ms $ease;
			transition-property: transform, opacity;
			@for $i from 1 through 20{
				&:nth-of-type(#{$i}){
					transition-delay: $i*120ms;
				}
			}
		}
	}
}

#pagetop{
	width: 64px;
	height: 64px;
	font-size: 1.12rem;
	border-radius: 50%;
	background: lighten($normal, 8%);
	color: #FFF;
	position: fixed;
	right: 24px;
	bottom: 24px;
	@include flex();
	align-items: center;
	justify-content: center;
	pointer-events: none;
	z-index: 2000;
	transform: rotate(-135deg) scale(.8);
	opacity: 0;
	transition: all 240ms $ease;
	@media screen and (max-width: $phone){
		right: 8px;
		bottom: 8px;
		width: 40px;
		height: 40px;
	}
	&.active{
		pointer-events: auto;
		transform: scale(1);
		opacity: 1;
	}
}


#loadView{
	@include flex();
	align-items: center;
	justify-content: center;
	z-index: 1000;
	.block{
		transform: translateY(-4%);
	}
	svg{
		display: block;
		width: 140px;
		margin: 0 auto;
		transform: translateX(10.4%);
		path{
			fill: $base;
		}
		.box{
			transform-origin: right top;
			opacity: 0;
		}
		.line{
			transform-origin: left bottom;
			opacity: 0;
		}
	}
	.ttl{
		width: 240px;
		margin: 24px auto 0;
		opacity: 0;
		img{
			width: 100%;
		}
	}
	&.active{
		$delay: 1400ms;
		.ttl{
			animation: viewTtl 1000ms $delay $ease forwards;
		}
		svg{
			.box{
				position: relative;
				z-index: 2;
				animation: viewBox 1000ms $delay $ease forwards;
			}
			.line{
				@for $i from 1 through 10{
					&:nth-of-type(#{$i}){
						animation: viewLine 800ms 80ms*($i - 1) $ease forwards;
					}
				}
			}
		}
        @keyframes viewTtl{
            0%{
                opacity: 0;
                transform: translateY(33.3%) scale(1.04);
            }
            100%{
                opacity: 1;
                transform: translate(0);
            }
        }
		@keyframes viewBox{
			0%{
				opacity: 0;
				transform: scale(.9);
			}
			100%{
				opacity: 1;
				transform: scale(1);
			}
		}
		@keyframes viewLine{
			0%{
				transform: scale(0) translate(100%, -100%) ;
			}
			30%{
				opacity: 0;
			}
			100%{
				opacity: 1;
				transform: scale(1);
			}
		}
	}
	&.remove{
		animation: removed 800ms 1000ms linear forwards;
		@keyframes removed {
			0%{
				opacity: 1;
			}
			100%{
				opacity: 0;
			}
		}
	}
}

.fadeto-enter-active, .fadeto-leave-active {
	transition: opacity 400ms $ease;
}
.fadeto-enter, .fadeto-leave-to {
	opacity: 0;
}
