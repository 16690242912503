#site-footer{
	background: darken($normal, 2%);
	color: rgba(#FFF,.88);
	a{
		color: rgba(#FFF,.88);
	}
	.pagetop{
		background: darken($gray, 4%);
		color: lighten($normal, 12%);
		display: block;
		padding: 20px 0;
		text-align: center;
		@media screen and (max-width: $phone){
			padding: 15px 0;	
		}
	}
	.footwrapper{
		padding: 88px 0;
		@media screen and (max-width: $tab){
			padding: 64px $tab-side;	
		}
		@media screen and (max-width: $phone){
			padding: 32px $sp-side;	
		}
		.company{
			margin: 0 0 48px;
			@include flex();
			align-items: center;
			justify-content: flex-start;
			position: relative;
			@media screen and (max-width: $phone){
				display: block;
				margin: 0 0 24px;
			}
			&__logo{
				margin: 0 32px 0 0;
				@media screen and (max-width: $phone){
					margin: 0;	
				}
				svg{
					width: 130px;
					display: block;
					@media screen and (max-width: $phone){
						width: 100px;
						margin: 0 auto;
					}
					path{
						fill: rgba(#FFF,.88);
					}
				}
			}
			&__data{
				font-size: .92rem;
				line-height: 1.66;
				@media screen and (max-width: $phone){
					text-align: center;
					margin: 8px 0 0;
				}
			}
			&__sns{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				@media screen and (max-width: $phone){
					position: relative;
					top: 0;
					right: 0;
					transform: translate(0);
					justify-content: center;
					margin: 24px 0;
				}
				li{
					margin: 0 10px 0 0;
					&:last-child{
						margin-right: 0;
					}
					a{
						$size: calc(32px + .2vmin);
						width: $size;
						height: $size;
						border-radius: 50%;
						font-size: .88rem;
						@include flex();
						align-items: center;
						justify-content: center;
						transition: transform 120ms $ease;
						position: relative;
						&:before{
							content: '';
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							border-radius: 50%;
							border: #FFF 1px solid;
							opacity: .16;
							z-index: 1;
							transition: all 120ms $ease;
							transition-property: transform, opacity;
						}
						i{
							display: inline-block;
							position: relative;
							z-index: 2;
							transition: transform 120ms $ease;
						}
						&:hover{
							transform: scale(.9);
							&:before{
								transform: scale(1.3);
								opacity: 1;
							}
							i{
								transform: scale(1.1);
							}
						}
					}
				}	
			}
		}
		.fnav{
			@include flex();
			align-items: flex-start;
			@media screen and (max-width: $phone){
				display: block;	
			}
			> dl{
				width: calc(25% - 32px);
				@media screen and (max-width: $phone){
					width: auto;
					border: rgba(#FFF,.08) 1px solid;
					margin: 0 0 20px;
					border-radius: 4px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
				&.clum{
					@include flex();
					@media screen and (max-width: $phone){
						display: block;	
					}
					dd{
						width: 50%;
						font-size: .72rem;
						@media screen and (max-width: $phone){
							width: auto;	
						}
					}
				}
				dt{
					width: 100%;
					border-bottom: rgba(#FFF,.12) 1px solid;
					padding: 0 0 10px;
					margin: 0 0 10px;
					@media screen and (max-width: $phone){
						padding: 12px 15px;
						margin: 0;
						background: rgba(#FFF,.04);
						border-bottom: rgba(#FFF,.08) 1px solid;
					}
				}
				dd{
					font-size: .92rem;
					margin: 0 0 4px;
					@media screen and (max-width: $phone){
						margin: 0;
						&:last-of-type{
							a{
								border-bottom: none;
							}
						}
					}
					i{
						display: inline-block;
						margin: 0 6px 0 0;
						&.r{
							margin: 0 0 0 6px;
							font-size: .64rem;
						}
					}
					a{
						display: inline-block;
						padding: 0 0 0 16px;
						position: relative;
						@media screen and (max-width: $phone){
							display: block;
							padding: 12px 15px;
							border-bottom: rgba(#FFF,.08) 1px solid;
						}
						&:before{
							content: '';
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background: rgba(#FFF,.64);
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							@media screen and (max-width: $phone){
								display: none;
							}
						}
						&:hover{
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	.copy{
		display: block;
		padding: 24px 0;
		font-size: .72rem;
		opacity: .64;
		text-align: center;
		background: rgba(#FFF,.04);
	}
}