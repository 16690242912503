.site-index{
    .aww{
		margin: 48px 0 0;
		border: darken($gray, 2%) 12px solid;
		padding: 32px 0;
		padding-top: 80px;
		display: block;
		color: $normal;
		position: relative;
		@media screen and (max-width: $phone){
			margin: 56px 0 0;
			border-width: 8px;
			padding: 64px 24px 24px;
		}
		&__pic{
			width: 280px;
			background: #FFF;
			padding: 0 32px 16px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			@media screen and (max-width: $phone){
				width: 200px;
				padding: 0 16px 16px;
			}
			img{
				width: 100%;
			}
		}
		&__title{
			font-size: 1.96rem;
			letter-spacing: 0em;
			margin: 0 0 16px;
			font-weight: 700;
			text-align: center;
			@media screen and (max-width: $phone){
				font-size: 1.32rem;
				width: 72%;
				margin: 0 auto 16px;
			}
		}
		&__txt{
			text-align: center;
			font-size: 1.12rem;
			@media screen and (max-width: $phone){
				font-size: 1rem;	
			}
		}
    }
	.second{
		content: '';
		position: relative;
		@media screen and (max-width: $tab){
			width: 100%;
			overflow: hidden;
		}
		@media screen and (max-width: $phone){
			background: $gray;	
		}
		&:before{
			content: '';
			position: absolute;
			z-index: -1;
			width: 88%;
			height: 100%;
			left: 0;
			top: 0;
			background: $gray;
			@media screen and (max-width: $tab){
				width: 80%;
			}
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&__box{
			position: relative;
			z-index: 2;
			@include flex();
			align-items: flex-end;
			justify-content: flex-start;
			@media screen and (max-width: $tab){
				display: block;
			}
			&__img{
				@media screen and (max-width: $tab){
					width: calc(100% + #{$tab-side * 2});	
					transform: translateX(#{$tab-side * -1});
				}
				img{
					width: 100%;
				}
			}
			&__txt{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				padding: 0 5.6vw;
				padding-left: calc(5.6vw + 360px);
				margin: 64px 0 0;
				position: relative;
				@media screen and (max-width: $tab){
					display: block;
					padding: 0;
					margin: 80px 0 0;
				}
				@media screen and (max-width: $phone){
					margin: 40px 0 0;	
				}
				h2{
					color: $base;
					font-size: 11rem;
					font-weight: 700;
					letter-spacing: 0em;
					line-height: 1;
					position: absolute;
					top: -48px;
					left: 5.6vw;
					transform: translateY(-50%);
					@media screen and (max-width: $tab){
						font-size: 9rem;
						top: -88px;
						left: 0;
						br{
							display: none;
						}	
					}
					@media screen and (max-width: $phone){
						font-size: 5.6rem;
						top: -52px;
					}
					.indent{
						display: inline-block;
					}
				}
				dl{
					dt{
						font-size: 1.32rem;
						font-weight: 700;
						margin: 0 0 4px;
						@media screen and (max-width: $phone){
							font-size: 1.2rem;	
						}
					}
					dd{
						line-height: 1.6;
						@media screen and (max-width: $phone){
							font-size: .92rem;	
						}
					}
				}
				.btnarea{
					position: absolute;
					margin: 0;
					top: 50%;
					right: 4vw;
					transform: translateY(-50%);
					@media screen and (max-width: $tab){
						right: 0;	
					}
					@media screen and (max-width: $phone){
						position: relative;
						top: 0;
						transform: translate(0);
						margin: 24px 0 0;
					}
				}
			}
		}
		&__listable{
			z-index: 2;
			margin: 64px 5.6vw 0;
			@media screen and (max-width: $tab){
				margin: 40px 0 0;	
			}
			&__list{
				@include flex();
				position: relative;
				z-index: 3;
				box-shadow: 0 0 24px rgba($normal, .04);
				@media screen and (max-width: $tab){
					justify-content: flex-start;
					box-shadow: none;
				}
				@media screen and (max-width: $phone){
					display: block;
				}
				&__item{
					background: #FFF;
					padding: 32px;
					width: calc(20% - 2px);
					height: auto;
					cursor: pointer;
					position: relative;
					@media screen and (max-width: $tab){
						width: calc(33.3% - 2px);
						margin: 2px 2px 0 0;
						&:nth-last-child(-n + 2){
							width: calc(50% - 2px);
							@media screen and (max-width: $phone){
								width: auto;	
							}
						}
					}
					@media screen and (max-width: $phone){
						width: auto;
						padding: 20px;
						padding-right: 56px;
						@include flex();
						align-items: center;
						margin: 0 0 2px;
						&:after{
							@include fontawesome('\f061');
							position: absolute;
							top: 50%;
							right: 20px;
							transform: translateY(-50%);
							font-size: .72rem;
							color: $base;
						}
					}
					&:before{
						content: '';
						position: absolute;
						top: 8px;
						left: 8px;
						width: calc(100% - 16px);
						height: calc(100% - 16px);
						border: $base 1px solid;
						z-index: 2;
						transform: scale(.96);
						opacity: 0;
						pointer-events: none;
						transition: transform 100ms $ease;
						transition-property: transform, opacity;
					}
					&:hover{
						&:before{
							transform: scale(1);
							opacity: 1;
						}
					}
					figure{
						width: 72px;
						margin: 0 auto 24px;
						@media screen and (max-width: $phone){
							width: 56px;
							margin: 0;
						}
						img{
							width: 100%;
						}
					}
					dl{
						position: relative;
						text-align: center;
						@media screen and (max-width: $phone){
							width: calc(100% - 56px);
							padding: 0 0 0 20px;
							text-align: left;
						}
						dt{
							font-weight: 700;
							font-size: 1.32rem;
							letter-spacing: .24em;
							line-height: 1;
							margin: 0 0 12px;
							@media screen and (max-width: $phone){
								font-size: 1.2rem;
								margin: 0 0 6px;
							}
							.sml{
								font-size: 1rem;
								letter-spacing: 0;
							}
							&:before{
								content: attr(data-eng);
								color: $base;
								letter-spacing: .024em;
								font-size: .72rem;
								display: block;
								margin: 0 0 8px 0;
							}
						}
						dd{
							line-height: 1.56;
							@media screen and (max-width: $phone){
								font-size: .92rem;	
							}
						}
					}
					.dispnone{
						display: none !important;
					}
				}
			}
            .overlay{
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($normal , .88);
                z-index: 9999;
				&__item{
					width: 640px;
					padding: 40px;
					background: #FFF;
					border-radius: 3px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					@media screen and (max-width: $phone){
						width: calc(100% - 30px);
						padding: 15px;
					}
					&__close{
						position: absolute;
						top: -12px;
						right: -12px;
						width: 48px;
						height: 48px;
						background: $normal;
						color: #FFF;
						border-radius: 50%;
						cursor: pointer;
						@include flex();
						align-items: center;
						justify-content: center;
						transition: transform 200ms $ease;
						@media screen and (max-width: $phone){
							width: 36px;
							height: 36px;
						}
						&:hover{
							transform: scale(1.2);
						}
					}
					&__box{
						max-height: 80vh;
						overflow: auto;
						@media screen and (max-width: $phone){
							max-height: calc(100vh - 60px);	
						}
						i{
							@include grd();
							-moz-background-clip: text;
							-webkit-background-clip: text;
							background-clip: text;
							font-size: 1.12rem;
							font-weight: 700;
							color: transparent;
							text-align: center;
							display: block;
						}
						h2{
							font-size: 2.24rem;
							line-height: 1.5;
							font-weight: 700;
							text-align: center;
							margin: 0 0 32px;
						}
						dl{
							margin: 40px 0 0;
							&:first-of-type{
								margin: 0;
							}
							dt{
								font-size: 1.24rem;
								font-weight: 700;
								background: darken($gray, 2%);
								padding: 8px 24px;
								margin: 0 0 20px;
							}
							dd{
								margin: 0 0 16px;
								&:last-of-type{
									margin: 0;
								}
							}
						}
                        ol{
                            li{
                                position: relative;
                                padding: 0 0 16px 36px;
                                margin: 0 0 16px;
                                border-bottom: $border 1px dashed;
                                &:last-child{
                                    margin-bottom: 0;
                                    padding-bottom: 0;
                                    border: none;
                                }
                                @for $i from 1 through 10{
                                    &:nth-child(#{$i}){
                                        &:before{
                                            content: '#{$i}';
                                        }
                                    }
                                }
                                &:before{
                                    content: '';
                                    font-size: .72rem;
                                    position: absolute;
                                    top: 2px;
                                    left: 0;
                                    width: 24px;
                                    height: 24px;
                                    background: $base;
                                    border-radius: 3px;
                                    color: #FFF;
                                    @include flex();
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
					}
				}
            }
		}
	}	
	.third{
		&__box{
			margin: 0 8%;
			padding: 0 0 0 7.2rem;
			position: relative;
			@media screen and (max-width: $tab){
				margin: 0;
				padding: 0;
			}
			&:after{
				content: '';
				width: 10rem;
				height: 1px;
				background: $base;
				position: absolute;
				left: 0;
				bottom: 48px;
				z-index: 2;
				@media screen and (max-width: $tab){
					display: none;
				}
			}
			&__title{
				position: absolute;
				top: 0;
				left: 0;
				font-size: 2.66rem;
				letter-spacing: .12em;
				line-height: 1;
				font-weight: 700;
				transform: translateY(40px);
				opacity: 0;
				@media screen and (min-width: $tab + 1px){
					-webkit-font-feature-settings : normal;
					font-feature-settings : normal;
					-webkit-writing-mode: vertical-rl;
					-moz-writing-mode: vertical-rl;
					-ms-writing-mode: tb-rl;
					writing-mode: vertical-rl;
					white-space: nowrap;
				}
				@media screen and (max-width: $tab){
					position: relative;
					text-align: center;
				}
				@media screen and (max-width: $phone){
					font-size: 1.72rem;
				}
				&:before{
					content: attr(data-eng);
					color: $base;
					font-size: .88rem;
					letter-spacing: .12em;
					display: block;
					margin: 0 0 0 16px;
					@media screen and (max-width: $tab){
						margin: 0 0 8px;	
					}
				}
				&._active{
					opacity: 1;
					transform: translateY(0);
					transition: all 400ms 20ms $ease;
				}
			}
			&__list{
				@include flex();
				padding: 32px 0 0;
				@media screen and (max-width: $phone){
					display: block;
					padding: 24px 0 0;
				}
				&__item{
					width: 48.5%;
					min-height: 400px;
					height: 27vw;
					padding: 32px;
					background: $gray;
					position: relative;
					@media screen and (max-width: $tab){
						min-height: 1px;
						height: auto;
						padding: 0;
					}
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0 0 16px;
						&:last-child{
							margin: 0;
						}
					}
					&:before{
						content: attr(data-eng);
						font-size: 1.66rem;
						line-height: 1;
						width: 64px;
						height: 64px;
						padding: 0 0 4px;
						color: #FFF;
						background: $base;
						position: absolute;
						top: 0;
						left: 24px;
						@include flex();
						align-items: center;
						justify-content: center;
						transform: translateY(-50%);
						z-index: 3;
						@media screen and (max-width: $tab){
							width: 48px;
							height: 48px;
							left: 0;
							transform: translate(0);
						}
					}
					> a{
						color: $normal;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 2;
						@media screen and (max-width: $tab){
							position: relative;
							display: block;
						}
					}
					&__pic{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						@media screen and (max-width: $tab){
							position: relative;
							height: calc(50vw * .6);
						}
						@media screen and (max-width: $phone){
							height: calc((100vw - #{$sp-side * 2}) *.6);
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					&__data{
						position: absolute;
						left: 0;
						bottom: 0;
						background: $gray;
						width: 100%;
						text-align: center;
						padding: 24px;
						@media screen and (max-width: $tab){
							position: relative;
						}
						dt{
							font-size: 1.16rem;
							line-height: 1.56;
							margin: 0 0 4px;
							font-weight: 700;
						}
						dd{
							font-size: .92rem;
						}
					}
				}
			}
		}
	}
	
	.blog{
		&__btn{
			position: absolute;
			top: 0;
			right: 0;
			@include grd();
			border: rgba($base,0) 2px solid;
			border-radius: 999px;
			color: #FFF;
			font-size: 1.12rem;
			padding: 6px 32px;
			&:hover{
				border-color: $base;
				background: transparent;
				color: $base;
			}
			@media screen and (max-width: $phone){
				padding: 4px 24px;
				top: -6px;
			}
			i{
				margin: 0 6px 0 0;
			}
		}
		h2,
		&__btn{
			opacity: 0;
			transform: translateY(20px);
            &._active{
                opacity: 1;
                transform: translateY(0);
                transition: all 400ms 20ms $ease;
				transition-property: opacity, transform;
            }
		}
		&__list{
			@include flex();
			@media screen and (max-width: $tab){
				display: block;
			}
			&__item{
				width: calc(50% - 12px);
				margin: 24px 0 0;
				@media screen and (max-width: $tab){
					width: auto;
					margin: 0 0 16px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				@media screen and (max-width: $phone){
					margin: 0 0 12px;	
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
				&__box{
					@include flex();
					background: #FFF;
					color: $normal;
					padding: 20px;
					box-shadow: 0 0 24px rgba($normal, .04);
					@media screen and (max-width: $phone){
						padding: 15px;	
					}
					&__pic{
						width: 150px;
						height: 150px;
						@media screen and (max-width: $phone){
							width: 80px;
							height: 80px;
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					&__data{
						width: calc(100% - 160px);
						padding: 0 0 0 20px;
						@media screen and (max-width: $phone){
							width: calc(100% - 80px);
							padding: 0 0 0 15px;
						}
						dl{
							margin: 12px 0 0;
							line-height: 1.56;
							@media screen and (max-width: $phone){
								margin: 8px 0 0;	
							}
							dt{
								font-size: 1.1rem;
								font-weight: 700;
								@media screen and (max-width: $phone){
									font-size: 1.04rem;	
								}
							}
							dd{
								font-size: .88rem;
								margin: 8px 0 0;
								@media screen and (max-width: $phone){
									display: none;
								}
							}
						}
					}
					time{
						background: darken($gray, 2%);
						color: $normal;
						font-size: .72rem;
						display: inline-block;
						padding: 2px 12px;
						margin: 0 6px 0 0;
					}
					.category{
						display: inline-flex;
						font-size: .72rem;
						@media screen and (max-width: $phone){
							@include flex();
							justify-content: flex-start;
							margin: 8px 0 0;
						}
						li{
							padding: 2px 12px;
							margin: 0 6px 0 0;
							background: $base;
							color: #FFF;
							@media screen and (max-width: $phone){
								
							}
						}
					}
				}
			}
		}
	}
	
	.videos{
		&__list{
			@include flex();
			justify-content: flex-start;
			margin: 0 0 0 -24px;
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
			&__item{
				width: 33.3%;
				padding: 0 0 0 24px;
				margin: 32px 0 0;
				@media screen and (max-width: $tab){
					width: 50%;
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 12px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				@media screen and (min-width: $tab + 1px){
					&:nth-child(-n+3){
						margin-top: 0;
					}
				}
				&__box{
					display: block;
					color: $normal;
					background: #FFF;
					height: 100%;
					padding: 0 0 80px;
					position: relative;
					@media screen and (max-width: $phone){
						padding: 0;	
					}
					&__thumb{
						width: 100%;
						padding: 56% 0 0;
						position: relative;
						img,
						iframe{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
							vertical-align: bottom;
						}
						&__cover{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							z-index: 2;
							background: rgba($normal, .48);
							@include flex();
							align-items: center;
							justify-content: center;
							color: #FFF;
							font-size: 4rem;
						}
					}
					&__text{
						text-align: center;
						padding: 15px;
						position: absolute;
						left: 0;
						width: 100%;
						height: 80px;
						line-height: 1.6;
						@include flex();
						align-items: center;
						justify-content: center;
						@media screen and (max-width: $phone){
							position: relative;
							height: auto;
						}
					}
				}
			}
		}
	}
	
	.meter{
		&__title{
			text-align: center;
			font-size: 1.12rem;
			font-weight: 700;
			line-height: 1;
			margin: 0 0 24px;
			&:before{
				content: attr(data-eng);
				font-size: 3.2rem;
				display: block;
				margin: 0 0 8px;
				@media screen and (max-width: $phone){
					font-size: 1.88rem;	
				}
			}
		}
		&__context{
			text-align: center;
			font-size: 1.12rem;
			line-height: 1.66;
			margin: 12px 0 32px;
			@media screen and (max-width: $phone){
				font-size: 1rem;
				margin-bottom: 0;
				&:after{
					content: '▼ 横にスクロールしてご覧下さい';
					display: block;
					margin: 16px 0;
					color: $base;
				}
			}
		}
		&__box{
			border: $border 1px solid;
			padding: 64px 0;
			padding-bottom: 32px;
			@media screen and (max-width: $phone){
				width: 100%;
				overflow: scroll;
				padding: 48px 15px 24px;
			}
		}
		.metarlist{
			@include flex();
			@media screen and (max-width: $phone){
				width: 200%;
				padding-right: 15px;
			}
			&._active{
				li{
					@for $i from 1 through 12{
						&:nth-child(#{$i}){
							.metarlist__item__data{
								> i{
									transform: scaleY(1);
									transition: transform 600ms $i*80ms $ease;
								}
								> span{
									transform: translate(-50%, 0) scale(1);
									transition: transform 600ms $i*80ms $ease;
								}
							}
						}
					}
				}
			}
			&__item{
				width: calc(100% / 12 - 1px);
				@include flex();
				border-right: darken($gray, 4%) 1px solid;
				&:last-child{
					border-right: 0;
				}
				&__title{
					width: 100%;
					margin: 16px 0 0;
					font-size: 1.08rem;
					line-height: 1;
					text-align: center;
					display: block;
					order: 2;
					@media screen and (max-width: $phone){
						font-size: .96rem;	
					}
				}
				&__data{
					width: 80%;
					height: 320px;
					background: darken($gray, 2%);
					margin: 0 auto;
					position: relative;
					border-radius: 4px;
					order: 1;
					@media screen and (max-width: $phone){
						height: 25vh;
						width: 72%;
					}
					&__bar{
                        background-color: #01BAEF;
                        background-image: linear-gradient(315deg, #abe9cd 0%, #01BAEF 74%);
						border-radius: 4px;
						width: 100%;
						position: absolute;
						left: 0;
						bottom: 0;
						transform: scaleY(0);
						transform-origin: center bottom;
					}
					@for $i from 0 through 100{
						.bar-#{$i}{
							height: $i * 1%;
							@if $i > 80 {
								background-color: #f5d020;
								background-image: linear-gradient(315deg, #f5d020 0%, #f53803 74%);
							}
							@elseif $i > 60{
								background-color: #f5d020;
								background-image: linear-gradient(315deg, #20bf55 0%, #f5d020 74%);
							}
							@elseif $i > 30{
								background-color: #20bf55;
								background-image: linear-gradient(315deg, #01BAEF 0%, #20bf55 74%);
							}
						}
					}
                    &__numb{
                        position: absolute;
                        top: -40px;
                        left: 50%;
						font-size: .88rem;
						font-weight: 700;
                        transform: translate(-50%, -15px) scale(0);
						color: $normal;
						white-space: nowrap;
						z-index: 2;
						@media screen and (max-width: $phone){
							top: -32px;	
						}
						strong{
							color: $base;
							font-size: 1rem;
							display: inline-block;
							margin: 0 2px 0 0;
						}
                    }
				}
			}
		}
	}
	
	.kussion{
		position: relative;
		overflow: hidden;
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				transform: scale(1.2);
			}
			&:before{
				background: url(/images/common/bg_contact.jpg) no-repeat center / cover;
			}
			&:after{
				background: #000;
				opacity: .56;
			}
		}
		&__data{
			text-align: center;
			position: relative;
			z-index: 2;
			color: rgba(#FFF,.88);
			h3{
				font-size: 1.12rem;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 24px;
				&:before{
					content: attr(data-eng);
					font-size: 3.34rem;
					display: block;
					margin: 0 0 12px;
					@media screen and (max-width: $phone){
						font-size: 2.4rem;	
					}
				}
			}
			.context{
				font-size: 1.08rem;
				@media screen and (max-width: $phone){
					margin: 0 30px;	
				}
			}
		}
	}
}