.site-env{
	.localnav{
		background: $gray;
		@include flex();
		align-items: center;
		padding: 16px 32px;
		border-radius: 4px;
		line-height: 1.2;
		margin: 0 0 48px;
		@media screen and (max-width: $phone){
			display: block;
			margin: 0 0 24px;
			padding: 16px;
			padding-top: 20px;
		}
		h3{
			font-weight: 700;
			width: 150px;
			border-right: rgba(#000, .16) 1px solid;
			@media screen and (max-width: $tab){
				width: 140px;	
			}
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 0 12px;
				padding: 0 0 12px;
				border-right: 0;
				border-bottom: $border 1px solid;
			}
		}
		ul{
			width: calc(100% - 150px);
			padding: 0 0 0 24px;
			@include flex();
			justify-content: flex-start;
			@media screen and (max-width: $tab){
				width: calc(100% - 140px);
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
			}
			li{
				margin: 0 4px 0 0;
				@media screen and (max-width: $phone){
					transform: translateX(-8px);
				}
				a{
					color: $normal;
					display: block;
					padding: 8px 24px;
					@media screen and (max-width: $tab){
						padding: 8px 16px;	
					}
					@media screen and (max-width: $phone){
						padding: 4px 8px;	
					}
					&.active,
					&:hover{
						border-radius: 4px;
						background: #FFF;
					}
				}
			}
		}
	}
	
	.caselist{
		&__item{
			margin: 0 0 48px;
			padding: 0 0 48px;
			border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				margin: 0 0 20px;
				padding: 0 0 20px;
			}
			&:last-child{
				margin: 0;
				padding: 0;
				border: none;
			}
			&__clum{
				@include flex();
				align-items: center;
				position: relative;
				@media screen and (max-width: $phone){
					display: block;	
				}
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 20px 0 20px 24px;
					border-color: transparent transparent transparent darken($gray, 12%);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					@media screen and (max-width: $phone){
						display: none;
					}
				}
				figure{
					width: calc(50% - 40px);
					padding: calc(67% / 2) 0 0;
					background: $gray;
					position: relative;
					overflow: hidden;
					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						padding: 6px 16px;
						z-index: 2;
						background: $base;
						color: #FFF;
						@media screen and (max-width: $phone){
							font-size: .88rem;	
						}
					}
					&:first-of-type{
						&:before{
							content: 'Before';
						}
					}
					&:last-of-type{
						&:before{
							content: 'After';
						}
					}
					@media screen and (max-width: $phone){
						width: 100%;
						padding: 67% 0 0;
						margin: 0 0 40px;
						overflow: visible;
						&:first-of-type{
							&:after{
								content: '';
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 16px 16px 0 16px;
								border-color:  darken($gray, 12%) transparent transparent transparent;
								position: absolute;
								left: 50%;
								bottom: -12px;
								transform: translate(-50%, 100%);
							}
						}
						&:last-of-type{
							margin: 0;
						}
					}
					img{
						width: 100%;
						height: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						object-fit: cover;
						object-position: center;
						transform: translate(-50%, -50%);
					}
				}
			}
			&__data{
				padding: 32px;
				background: darken($gray, 2%);
				margin: 20px 0 0;
				@include flex();
				align-items: center;
				justify-content: flex-start;
				@media screen and (max-width: $phone){
					padding: 16px;	
				}
				dt{
					font-size: 1.24rem;
					font-weight: 700;
					@media screen and (max-width: $phone){
						width: 100%;	
					}
				}
				dd{
					&.cate{
						padding: 0 0 0 32px;
						@media screen and (max-width: $phone){
							padding: 8px 0 0;	
						}
						ul{
							@include flex();
							align-items: center;
							justify-content: flex-start;
							li{
								font-size: .88rem;
								color: #FFF;
								background: $base;
								border-radius: 2px;
								padding: 2px 12px;
								margin: 0 4px 0 0;
								&:last-child{
									margin: 0;
								}
							}
						}
					}
					&.desc{
						width: 100%;
						margin: 16px 0 0;
						padding: 16px 0 0;
						border-top: #FFF 2px solid;
						font-size: .96rem;
						@media screen and (max-width: $phone){
							font-size: .92rem;	
						}
					}
				}
			}
		}
	}
}