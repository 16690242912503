.site-service{
	.midttl{
		font-size: 1.88rem;
		font-weight: 700;
		text-align: center;
		margin: 0 0 24px;
		@media screen and (max-width: $phone){
			font-size: 1.24rem;	
		}
		&:before{
			content: attr(data-eng);
			display: block;
			font-size: .88rem;
			line-height: 1;
			color: $base;
			margin: 0 0 6px;
			@media screen and (max-width: $phone){
				font-size: .72rem;	
			}
		}
	}
	.localnav{
		background: $gray;
		padding: 16px 16%;
		border-radius: 4px;
		line-height: 1.2;
		@media screen and (max-width: $tab){
			padding: 16px;	
		}
		ul{
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: calc(50% - 12px);
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 12px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				a{
					color: $normal;
					border: #FFF 2px solid;
					background: #FFF;
					text-align: center;
					font-size: 1.12rem;
					padding: 16px 24px;
					border-radius: 4px;
					box-shadow: 0 0 16px rgba($normal, .08);
					display: block;
					&:hover{
						border-color: $base;
						color: $base;
					}
				}
			}
		}
	}
	.sec{
		padding: 96px 0 0;
		@media screen and (max-width: $phone){
			padding: 48px 0 0;	
		}
		&:first-of-type{
			padding: 48px 0 0;
			@media screen and (max-width: $phone){
				padding: 32px 0 0;	
			}
		}
		&__title{
			font-size: 2.24rem;
			font-weight: 700;
			text-align: center;
			margin: 0 0 28px;
			@media screen and (max-width: $phone){
				font-size: 1.32rem;
				margin: 0 0 20px;
			}
		}
		
		&__box{
			margin: 0 0 56px;
			@media screen and (max-width: $phone){
				margin: 0 0 32px;	
			}
			&:last-of-type{
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: none;
			}
			&__clum{
				@include flex();
				position: relative;
				@media screen and (max-width: $phone){
					display: block;	
				}
				&.rvs{
					.sec__box__clum__pic{
						order: 2;
					}
					.sec__box__clum__data{
						order: 1;
					}
				}
				&__pic{
					width: 500px;
					height: 350px;
					position: relative;
					@media screen and (max-width: $tab){
						width: 500px * .6;
						height: 350px * .6;
					}
					@media screen and (max-width: $phone){
						width: auto;
						height: auto;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				&__data{
					width: calc(100% - 500px - 48px);
					position: relative;
					@media screen and (max-width: $tab){
						width: calc(100% - (500px *.6) - 48px);
					}
					@media screen and (max-width: $phone){
						width: auto;
						margin: 20px 0 0;
					}
					&:before{
						content: '';
						width: 100%;
						height: 1px;
						background: darken($border, 1%);
						position: absolute;
						right: 0;
						bottom: 0;
						@media screen and (max-width: $tab){
							display: none;
						}
					}
					>dl{
						margin: 0 0 40px;
						&:last-of-type{
							margin-bottom: 0;
						}
						dt{
							font-size: 1.24rem;
							font-weight: 700;
							margin: 0 0 16px;
							padding: 8px 16px;
							background: $gray;
							padding-left: 20px;
							position: relative;
							&:before{
								content: '';
								width: 4px;
								height: 100%;
								background: $base;
								position: absolute;
								top: 0;
								left: 0;
							}
						}
						dd{
							font-size: .96rem;
							text-align: justify;
						}
					}
				}
			}
		}
	}
}