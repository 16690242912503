.site-company{
	.dataset{
		margin: 20px 0 0;
		&.not{
			margin-top: 0;
		}
		dt{
			font-size: .92rem;
			background: $base;
			color: #FFF;
			display: inline-block;
			padding: 1px 24px;
			margin: 0 0 6px;
		}
		&__str{
			color: $base;
			display: inline-block;
		}
	}
	
	.historylist{
		position: relative;
		&:before{
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			left: 205px;
			top: 0;
			background: $base;
			@media screen and (max-width: $phone){
				left: 124px;	
			}
		}
		> li{
			list-style: none;
			margin: 0 0 24px;
			padding: 0 0 24px;
			border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				margin: 0 0 16px;
				padding: 0 0 16px;
			}
			&:last-child{
				margin: 0;
			}
			dl{
				font-size: 1.04rem;
				@include flex();
				dt{
					width: 240px;
					font-weight: 700;
					@media screen and (max-width: $phone){
						width: 120px;	
					}
				}
				dd{
					width: calc(100% - 240px);
					padding: 0 0 0 40px;
					position: relative;
					@media screen and (max-width: $phone){
						width: calc(100% - 120px);
						padding: 0 0 0 40px;
					}
					&:before{
						content: '';
						width: 14px;
						height: 14px;
						background: $base;
						border-radius: 50%;
						position: absolute;
						top: 50%;
						left: -41px;
						transform: translateY(-50%);
						@media screen and (max-width: $phone){
							width: 10px;
							height: 10px;
							left: 0;
						}
					}
				}
			}
		}
	}
	.access{
		@include flex();
		justify-content: flex-start;
		margin: 0 0 0 -24px;
		@media screen and (max-width: $phone){
			display: block;
			margin: 0;
		}
		&__item{
			width: 33.3%;
			padding: 0 0 0 24px;
			margin: 24px 0 0;
			position: relative;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 0 0 12px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			&:nth-child(-n+3){
				margin-top: 0;
			}
			dl{
				background: #FFF;
				height: 100%;
				padding: 24px;
				box-shadow: 0 0 24px rgba($normal, .08);
				position: relative;
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 12px 12px 0 0;
					border-color: $base transparent transparent transparent;
					position: absolute;
					top: 8px;
					left: 8px;
				}
			}
			dt{
				font-size: 1.16rem;
				font-weight: 700;
				margin: 0 0 8px;
				padding: 0 0 8px;
				border-bottom: $border 1px solid;
			}
			dd{
				font-size: .92rem;
				line-height: 1.66;
			}
		}
	}
	.map{
		iframe{
			width: 100%;
			height: 400px;
			vertical-align: bottom;
			filter: grayscale(1);
		}
	}
}